import React, { useState, useEffect } from 'react';
import { API } from '../../env';
import Pagination from '../../components/pagination/Pagination';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Accounts-receivable.css'; 

const AccountsReceivable = () => {
  localStorage.setItem('selectedView', 'accounts-receivable');
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: ''
  });
  const [clientType, setClientType] = useState('CUSTOMER'); // Default value
  const [reportData, setReportData] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState({});
  const pageSize = 20; // Adjust the page size as needed

  useEffect(() => {
    // Set the endDate to today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];
    setFormData(prevFormData => ({
      ...prevFormData,
      endDate: today
    }));
  }, []);

  useEffect(() => {
    if (page < 1) setPage(1); // Ensure page is positive
    fetchReportData();
  }, [page, formData, clientType]); // Fetch data on page or formData change

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleClientTypeChange = (event) => {
    setClientType(event.target.value);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= paginator.totalPages) { // Ensure page is within bounds
      setPage(newPage);
    }
  };

  const fetchReportData = async () => {
    if (!formData.startDate || !formData.endDate) return;

    setSubmitDisabled(true);
    setLoading(true);

    const requestData = {
      ...formData,
      clientType,
      reportType: 'AccountReceivable'
    };

    try {
      const url = new URL(`${API}/api/v1/reports/accountReport`);
      url.searchParams.append('page', page);
      url.searchParams.append('pageSize', pageSize);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        const result = await response.json();
        setPaginator(result);
        setReportData(result);
        toast.success("Report fetched successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
      } else {
        console.error("Error fetching report", response.statusText);
        toast.error(`Error fetching report: ${response.statusText}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000
        });
      }
    } catch (error) {
      console.error("The report could not be fetched", error);
      toast.error("The report could not be fetched", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      });
    } finally {
      setSubmitDisabled(false);
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    fetchReportData(); // Fetch data when form is submitted
  };

  return (
    <div className="reports-container">
      <h1>Accounts Receivable</h1>
      <form onSubmit={handleSubmit}>
        <div className='formbox'>
          <div className="formitem">
            <label htmlFor="startDate">Start Date :</label>
            <input 
              type="date" 
              id="startDate" 
              value={formData.startDate} 
              onChange={handleChange} 
              required
            />
          </div>
          <div className="formitem">
            <label htmlFor="endDate">End Date :</label>
            <input 
              type="date" 
              id="endDate" 
              value={formData.endDate} 
              onChange={handleChange} 
              required 
            />
          </div>
          <div className="formitem">
            <label>Client Type</label>
            <div>
                <input 
                  type="radio" 
                  value="CUSTOMER" 
                  checked={clientType === 'CUSTOMER'}
                  onChange={handleClientTypeChange} 
                />
              <label > Customer  </label>

              <input 
                  type="radio" 
                  value="SUPPLIER" 
                  checked={clientType === 'SUPPLIER'}
                  onChange={handleClientTypeChange} 
                />
                <label>
              
              Supplier
            </label>

            </div>
          </div>
          <br></br>
          <div className="button-container">
            <button className="btn" type="submit" disabled={submitDisabled}>
              {loading ? 'Generating Report...' : 'Generate Report'}
            </button>
          </div>
        </div>
      </form>

      {reportData && (
        <div className="report-results">
          <h2 align="center"><b>Report from {formData.startDate} to {formData.endDate}</b></h2>
          <table className="styled-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {reportData.balancesRecords.length > 0 ? (
                reportData.balancesRecords.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.name}</td>
                    <td>{entry.balance}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No results found</td>
                </tr>
              )}
              {page === paginator.totalPages && paginator.balancesRecords.length > 0 && (
                <tr style={{ backgroundColor: 'lightblue', color: 'black' }}>
                  <td style={{ fontWeight: 'bold' }}>Total</td>
                  <td style={{ fontWeight: 'bold' }}>{reportData.totalAmount}</td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination paginator={paginator} onChangePage={handlePageChange} />
        </div>
      )}
    </div>
  );
};

export default AccountsReceivable;
