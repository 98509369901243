import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userVerification from '../../../utils/userVerification';
import { API } from '../../../env';
import '../../../styles/new-edit-form.css';
import trimFormValues from '../../../utils/trimFormValues';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewProvider = () => {
    localStorage.setItem('selectedView', 'providers');
    const navigate = useNavigate();

    useEffect(() => {
        // Permission validation
        if (!userVerification().isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }
    }, [navigate]);

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: ''
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const trimmedFormData = trimFormValues(formData);

        setSubmitDisabled(true);
        try {
            const response = await fetch(`${API}/api/v1/provider`, {
                method: 'POST',
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(trimmedFormData),
            });

            if (response.ok) {
                console.log('Supplier created Successfully');
                toast.success("Supplier created Successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  })
                  
                navigate('/providers');
                return;
            }
            console.error("The supplier could not be created, please check the data");
            toast.error("The supplier could not be created, please check the data", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
              })
            
        } catch (error) {
            console.log(error);
            toast.error("Error creating supplier", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
              })
            
        }
        setSubmitDisabled(false);
    }

    return (
        <div className="newProvider-container">

            <div className="text">New Supplier</div>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="grid-form">
                        <div className="form-item">
                            <label htmlFor="name">Name</label>
                            <input
                                className="input"
                                type="text"
                                id="name"
                                maxLength="45"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-item">
                            <label htmlFor="phoneNumber">Phone</label>
                            <input
                                className="input"
                                type="text"
                                id="phoneNumber"
                                maxLength="20"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-item">
                            <label htmlFor="email">Email</label>
                            <input
                                className="input"
                                type="email"
                                id="email"
                                maxLength="100"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="button-container">
                        <button className="btn" type="submit" disabled={submitDisabled}>
                        Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewProvider;
