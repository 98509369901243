    import React, { useEffect, useState } from 'react';
    import { useNavigate } from 'react-router-dom';
    import userVerification from '../../../utils/userVerification';
    import '../../../styles/new-edit-form.css';
    import { API } from '../../../env';
    import SearchSelect from '../../../components/search-select/SearchSelect';
    import './new-sale.css';
    import ItemSelection from './item-selection/ItemSelection';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import {  faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
    import { toast } from "react-toastify";
    import "react-toastify/dist/ReactToastify.css";


    const NewSale = () => {
        localStorage.setItem('selectedView', 'sales');
        const navigate = useNavigate();

        const [submitDisabled, setSubmitDisabled] = useState(false);

        const [formData, setFormData] = useState({
            customerId: 0,
            articles: [],
            sessionUserId: 0,
            billInvoiceNumber:'',
            saleDate:new Date().toISOString().split('T')[0] 
        });
        toast.configure();
        // Handler for invoice bill number change
        const handleInvoiceBillNumberChange = (billInvoiceNumber) => {
            setFormData(prevData => ({
                ...prevData,
                billInvoiceNumber
            }));
        };

        // Handler for sale date change
        const handleSaleDateChange = (saleDate) => {
            setFormData(prevData => ({
                ...prevData,
                saleDate
            }));
        };

        useEffect(() => {
            // Permission validation
            const userVer = userVerification();
            if (!userVer.isAuthenticated) {
                localStorage.clear();
                navigate('/login');
                return;
            }

            // Initialize form data
            setFormData({
                ...formData,
                sessionUserId: userVer.user.userId
            });
            // eslint-disable-next-line
        }, [navigate]);

        const handleCustomerSelect = (customer) => {
            setFormData({
                ...formData,
                customerId: customer.customerId
            });
        }

        const onSelectionChange = (articles) => {
            setFormData({
                ...formData,
                articles: articles.map(a => ({articleId: a.articleId, articleQuantity: a.quantity,salePrice:a.salePrice}))
            });
        }

        const handleSubmit = async (event) => {
            event.preventDefault();
            if (formData.articles.length === 0) {
                alert('You must select at least one item');
                return;
            }
            setSubmitDisabled(true);
            try {
                const response = await fetch(`${API}/api/v1/sale`, {
                    method: 'POST',
                    headers: {
                        'Authorization': localStorage.getItem('jwtToken'), 
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    console.log('Sale registered successfully');
                    toast.success("Sale registered successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                      });
                    navigate('/sales');
                    return;
                }
                console.error("The sale could not be registered, verify the data");
                toast.error("The sale could not be registered, verify the data", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                  });
            } catch (error) {
                console.log(error);
                alert("Error registering sale");
            }
            setSubmitDisabled(false);
        }

        return (
            <div className="newSale-container">

                <div className="text">New Sale <FontAwesomeIcon icon={faHandHoldingDollar} className="sales-icon" /> </div>
                <div className="form-container">
                    <form onSubmit={handleSubmit} autocomplete="off">
                        <div className="grid-form">
                            <SearchSelect
                                label="Customer"
                                placeholder="Search for customer..."
                                onSelected={handleCustomerSelect}
                                apiUrl={`${API}/api/v1/customer`}
                                optionsAttr="customers"
                                isRequired={true}
                            />
                        </div>
                        

                        <ItemSelection 
                            onSelectionChange={onSelectionChange}
                            onInvoiceBillNumberChange={handleInvoiceBillNumberChange}
                            onSaleDateChange={handleSaleDateChange}
                       />

                        {formData.articles.length > 0 && (
                            <div className="button-container">
                                <button className="btn" type="submit" disabled={submitDisabled}>
                                    Create
                                </button>
                            </div>
                        )}
                    </form>
                </div>

            </div>
        );
    }

    export default NewSale;
