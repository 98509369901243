import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './itemselection.css';
import SearchBox from '../../../../components/search-box/SearchBox';
import Pagination from '../../../../components/pagination/Pagination';
import { API } from '../../../../env';
import userVerification from '../../../../utils/userVerification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../../components/loading/Loading';

const ItemSelection = ({ onSelectionChange, onInvoiceBillNumberChange,onPurchaseDateChange,provider }) => {
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    const pageSize = 10;

    const [isLoading, setIsLoading] = useState(true);

    const [paginator, setPaginator] = useState({});

    const navigate = useNavigate();

    const [articles, setArticles] = useState([]);
    const today = new Date().toISOString().split('T')[0];
    const [purchaseDate, setPurchaseDate] = useState(today);

    const [invoiceBillNumber, setInvoiceBillNumber] = useState('');

    useEffect(() => {
        if (!provider) {
            return;
        }

        // Permission validation
        if (!userVerification().isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }

        // Query paginated data
        const data = new FormData();
        if (query.length > 0) {
            data.append('searchCriteria', query);
        }
        data.append('providerId', provider.providerId);
        data.append('page', page);
        data.append('pageSize', pageSize);

        const url = new URL(`${API}/api/v1/article`);
        url.search = new URLSearchParams(data).toString();
        (async () => {
              await fetch(url,{ 
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json' 
                }
            })
                .then(response => response.json())
                .then(data => {
                    setPaginator(data);
                    setIsLoading(false);
                })
                .catch(error => console.log(error))
        })();
    }, [navigate, query, page, provider]);

    const handleSearch = (query) => {
        setQuery(query);
    }

    const handlePage = (page) => {
        setPage(page);
    }

   

    const purchaseHeaderStyle = {
        backgroundColor: 'orange',
        color: 'white',
        padding: '10px',
        textAlign: 'center',
      };

    const handleCheckboxChange = (article, isChecked) => {
        if (isChecked) {
            article.quantity = 1;
            setArticles([...articles, article]);
            onSelectionChange([...articles, article]);
        } else {
            setArticles(articles.filter(a => a.articleId !== article.articleId));
            onSelectionChange(articles.filter(a => a.articleId !== article.articleId));
        }
    }

    const handlePurchasePriceChange = (article, newPrice) => {
        newPrice = parseFloat(newPrice) || 0; // Ensure valid number
        const updatedArticles = articles.map(
            a => a.articleId === article.articleId ? { ...a, purchasePrice : newPrice } : a
        );
        setArticles(updatedArticles);
        onSelectionChange(updatedArticles);
    }

    const handleQuantityChange = (article, newQuantity) => {
        if (newQuantity.length === 0) {
            newQuantity = 1;
        } else {
            newQuantity = parseInt(newQuantity, 10);
        }
        const updatedArticles = articles.map(
            a => a.articleId === article.articleId ? { ...a, quantity: newQuantity } : a
        );
        setArticles(updatedArticles);
        onSelectionChange(updatedArticles);
    }

    const calculateTotal = () => {
        return articles.reduce((total, article) => {
            return total + article.quantity * article.purchasePrice;
        }, 0);
    }
    const handleInvoiceNumberChange = (event) => {
        const newInvoiceNumber = event.target.value;
        setInvoiceBillNumber(newInvoiceNumber);
        if (onInvoiceBillNumberChange) {
            onInvoiceBillNumberChange(newInvoiceNumber);
        }
    };

    const handleInvoiceDateChange = (event) => {
        const newInvoiceDate = event.target.value;
        setPurchaseDate(newInvoiceDate);
        if (onPurchaseDateChange) {
            onPurchaseDateChange(newInvoiceDate);
        }
    };
    

    return (
        <div className="item-selection-container">
            <div className="top-articles">
                <label className="provider-item-label">Select items{provider ? " from provider " + provider.name : ""}</label>
                <div className="options">
                    <SearchBox onSearch={handleSearch} disabled={isLoading} />
                </div>
            </div>

            {!isLoading ? (
                <div className="table-container">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>NAME</th>
                                <th>MODEL NUMBER</th>
                                <th>CATEGORY</th>
                                <th>STOCK</th>
                                <th>PURCHASE PRICE</th>
                                <th>SALE PRICE</th>
                                <th>SUPPLIER</th>
                                <th>SELECT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginator.articles && paginator.articles.length > 0 ? (
                                paginator.articles.map(article => (
                                    <tr key={article.articleId}>
                                        <td>{article.articleId}</td>
                                        <td>{article.name}</td>
                                        <td>{article.modelNumber}</td>
                                        <td>{article.category.name}</td>
                                        <td>{article.stock}</td>
                                        <td>{article.purchasePrice}</td>
                                        <td>{article.salePrice}</td>
                                        <td>{article.provider.name}</td>
                                        <td>
                                            <label className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    checked={!!articles.find(a => a.articleId === article.articleId)}
                                                    onChange={(event) => handleCheckboxChange(article, event.target.checked)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="9">No results found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Pagination paginator={paginator} onChangePage={handlePage} />
                </div>
            ) : (
                <Loading />
            )}

            {articles.length > 0 && (
                <div className="purchaseSummary">
                    <div className="top-purchase">
                        <hr></hr>
                        <label className='summary-label'>Purchase summary</label>
                        <div className="summaryHeader">
                        <div className="form-group">
                        <label htmlFor="invoiceDate">Invoice Date:</label>

                        <input
                            type="date"
                            id="purchaseDate"
                            name="purchaseDate"
                            value={purchaseDate}
                            onChange={handleInvoiceDateChange}
                            className="form-control"
                        />
                    </div>
                    {/* Invoice Bill Number Field */}

                    <div className="form-group">
                        <label htmlFor="invoiceBillNumber">Invoice Bill Number:</label>
                        <input
                            type="text"
                            id="invoiceBillNumber"
                            name="invoiceBillNumber"
                            value={invoiceBillNumber}
                            onChange={handleInvoiceNumberChange}
                            className="form-control"
                        />
                    </div>

                    </div>
                       
                    </div>
                    <div className="table-container">
                        <table className="table">
                            <thead>
                                <tr style={purchaseHeaderStyle}>
                                    <th>ID</th>
                                    <th>NAME</th>
                                    <th>MODEL NUMBER</th>
                                    <th>STOCK</th>
                                    <th>QUANTITY</th>
                                    <th>PRICE</th>
                                    <th>SUBTOTAL</th>
                                    <th>REMOVE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {articles && articles.map(article => (
                                    <tr key={article.articleId}>
                                        <td>{article.articleId}</td>
                                        <td>{article.name}</td>
                                        <td>{article.modelNumber}</td>
                                        <td>{article.stock}</td>
                                        <td>
                                            <input
                                                className="input"
                                                type="number"
                                                min="1"
                                                value={article.quantity}
                                                onChange={(event) => handleQuantityChange(article, event.target.value)}
                                                required
                                            />
                                        </td>
                                        <td>
                                        <input
                                            className="input"
                                            type="number"
                                            min="0"  
                                            step=".05"              
                                            value={article.purchasePrice || ''}  // Ensure value is set correctly
                                            onChange={(event) => handlePurchasePriceChange(article, event.target.value)}
                                            required
                                        />
                                        </td>
                                        <td>{(article.purchasePrice * article.quantity)}</td>
                                        <td>
                                            <FontAwesomeIcon icon={faTrashCan} className="trash-icon" onClick={() => handleCheckboxChange(article, false)} />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="5"></td>
                                    <td className="total">TOTAL</td>
                                    <td className="total">{calculateTotal()}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ItemSelection;