import React, { useEffect, useState } from 'react';
import { API } from '../../env';
import Pagination from '../../components/pagination/Pagination';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Inventory-report.css'; // Ensure this CSS file is created for styling

const InventoryReport = () => {
    localStorage.setItem('selectedView', 'inventory-report');
    const [reportData, setReportData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const pageSize = 50;
    const [paginator, setPaginator] = useState({});

    useEffect(() => {
        const fetchReportData = async () => {
            setIsLoading(true);
            try {
                const data = new FormData();
                data.append('page', page);
                data.append('pageSize', pageSize);

                const url = new URL(`${API}/api/v1/reports/inventoryReport`);
                url.search = new URLSearchParams(data).toString();

                const response =   await fetch(url,{ 
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json' 
                }
            });
                if (response.ok) {
                    const result = await response.json();
                    setPaginator(result);
                } else {
                    throw new Error('Failed to fetch report data');
                }
            } catch (error) {
                console.error("Error fetching report data", error);
                toast.error("Error fetching report data", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchReportData();
    }, [page]);

    const handlePageChange = (page) => {
        setPage(page);
    };

    return (
        <div className="reports-container">
            <h1>Inventory Report</h1>

            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className="tabled-container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Item Code</th>
                                <th>Present Stock</th>
                                <th>P-Price</th>
                                <th>S-Price</th>
                                <th>Stock Value</th>
                                <th>Supplier</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginator.articles && paginator.articles.length > 0 ? (
                                paginator.articles.map((entry, index) => (
                                    <tr key={entry.articleid}>
                                        <td>{entry.name}</td>
                                        <td>{entry.itemCode}</td>
                                        <td>{entry.stock}</td>
                                        <td>{entry.purchasePrice}</td>
                                        <td>{entry.salePrice}</td>
                                        <td>{entry.stock * entry.purchasePrice}</td>
                                        <td>{entry.provider.name}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No results found</td>
                                </tr>
                            )}
                            {paginator.page === paginator.totalPages && paginator.articles && (
                             <tr style={{ backgroundColor: 'lightblue', color: 'black' }}>
                             
                            <td style={{ fontWeight: 'bold' }}>Total</td>
                            <td></td>
                             <td style={{ fontWeight: 'bold' }}>{paginator.totalStock}</td>
                             <td></td>
                             <td></td>
                             <td style={{ fontWeight: 'bold' }}>{paginator.totalPrice}</td>
                             <td></td>
                             
                         </tr>
                            )}
                        </tbody>
                    </table>

                    <Pagination paginator={paginator} onChangePage={handlePageChange} />
                </div>
            )}
        </div>
    );
};

export default InventoryReport;
