import React, { useEffect, useState } from 'react';
import '../../../styles/new-edit-form.css';
import './new-category.css'
import { useNavigate } from 'react-router-dom';
import userVerification from '../../../utils/userVerification';
import { API } from '../../../env';
import trimFormValues from '../../../utils/trimFormValues';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewCategory = () => {
    localStorage.setItem('selectedView', 'categories');
    const navigate = useNavigate();
    
    useEffect(() => {
        // Permission validation
        if (!userVerification().isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }
    }, [navigate]);

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [formData, setFormData] = useState({
        name: ''
    });
   
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const trimmedFormData = trimFormValues(formData);

        setSubmitDisabled(true);
        try {
            const response = await fetch(`${API}/api/v1/category`, {
                method: 'POST',
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(trimmedFormData),
            });

            if (response.ok) {
                toast.success("Category created successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false
                  });
                  
              //  alert('Category created successfully');
              
                navigate('/categories');
                return;
            }
            toast.error("The category could not be created, please check the data", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
              });
          
        } catch (error) {
            console.log(error);
            toast.error("Error al crear la categoría", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 20000
              });
           // alert("Error al crear la categoría");
        }
        setSubmitDisabled(false);
    }

    return (
        <div className="newCategory-container">

            <div className="text">New  Category</div>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="grid-form">
                        <div className="form-item">
                            <label htmlFor="name">Name</label>
                            <input
                                className="input"
                                type="text"
                                id="name"
                                maxLength="45"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="button-container">
                        <button className="btn" type="submit" disabled={submitDisabled}>
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewCategory;
