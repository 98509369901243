import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import userVerification from '../../../utils/userVerification';
import '../../../styles/new-edit-form.css';
import { API } from '../../../env';
import SearchSelect from '../../../components/search-select/SearchSelect';
import trimFormValues from '../../../utils/trimFormValues';
import Loading from '../../../components/loading/Loading';

const EditItem = () => {
    localStorage.setItem('selectedView', 'items');
    const { id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        brand: '',
        stock: 0,
        purchasePrice: 0,
        salePrice: 0,
        weight: '',
        providerId: 0,
        categoryId: 0,
        modelNumber:'',
        color:'',
        description:'',
        size:'',
        itemCode:''

    });

    const [relationalData, setRelationalData] = useState({
        provider: {},
        category: {}
    });

    useEffect(() => {
        // Permission validation
        if (!userVerification().isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }

        // Query data
        (async () => {
            const url = new URL(`${API}/api/v1/article/${id}`);
              await fetch(url,{ 
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json' 
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (!data || data.error) {
                        navigate('/items');
                        return;
                    }
                    setFormData({
                        name: data.name,
                        modelNumber: data.modelNumber,
                        stock: data.stock,
                        purchasePrice: data.purchasePrice,
                        salePrice: data.salePrice,
                        itemCode: data.itemCode,
                        providerId: data.provider.providerId,
                        categoryId: data.category.categoryId,
                        color: data.color,
                        description: data.description,
                        size: data.size
                    });
                    setRelationalData({
                        provider: data.provider,
                        category: data.category
                    });
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    navigate('/items');
                    return;
                })
        })();
    }, [id, navigate]);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        });
    }

    const handleProviderSelect = (provider) => {
        setFormData({
            ...formData,
            providerId: provider.providerId
        });
    }

    const handleCategorySelect = (category) => {
        setFormData({
            ...formData,
            categoryId: category.categoryId
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const trimmedFormData = trimFormValues(formData);

        setSubmitDisabled(true);
        try {
            const response = await fetch(`${API}/api/v1/article/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'),
                    'Content-Type': 'application/json',


                },
                body: JSON.stringify(trimmedFormData),
            });

            if (response.ok) {
                alert('Item updated successfully.');
                navigate('/items');
                return;
            }
            alert("The item could not be updated, please check the data.");
        } catch (error) {
            console.log(error);
            alert("Error updating the item");
        }
        setSubmitDisabled(false);
    }

    return (
        <div className="editItem-container">

            <div className="text">Edit Item</div>
            {!isLoading ? (
                <div className="form-container">
                    <form onSubmit={handleSubmit} autocomplete="off">
                    <div className="grid-form">
                        <div className="form-item">
                            <label htmlFor="name">Name</label>
                            <input
                                className="input"
                                type="text"
                                id="name"
                                maxLength="45"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="two-together">
                        <div className="form-item">
                                <label htmlFor="itemCode">Item Code</label>
                                <input
                                    className="input"
                                    type="text"
                                    id="itemCode"
                                    maxLength="15"
                                    value={formData.itemCode}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-item">
                                <label htmlFor="stock">Stock</label>
                                <input 
                                    className="input"
                                    type="number"
                                    id="stock"
                                    min="0"
                                    value={formData.stock}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            
                           
                        </div>
                        <div className="form-item">
                            <label htmlFor="modelNumber">Model Number</label>
                            <input
                                className="input"
                                type="text"
                                id="modelNumber"
                                maxLength="45"
                                value={formData.modelNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="two-together">
                            <div className="form-item">
                                <label htmlFor="color">Color</label>
                                <input
                                    className="input"
                                    type="text"
                                    id="color"
                                    value={formData.color}
                                    onChange={handleChange}
                                    
                                />
                            </div>
                            <div className="form-item">
                                <label htmlFor="size">Size </label>
                                <input
                                    className="input"
                                    type="text"
                                    id="size"
                                    value={formData.size}
                                    onChange={handleChange}
                                    
                                />
                            </div>
                        </div>

                        <div className="form-item">
                            <label htmlFor="description">Description</label>
                            <input
                                className="input"
                                type="text"
                                id="description"
                                maxLength="45"
                                value={formData.description}
                                onChange={handleChange}
                                
                            />
                        </div>

                        <div className="two-together">
                            <div className="form-item">
                                <label htmlFor="purchasePrice">Purchase Price</label>
                                <input
                                    className="input"
                                    type="number"
                                    id="purchasePrice"
                                    min="0"
                                    step="0.01" // Allows decimal values with up to two decimal places
                                    value={formData.purchasePrice}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-item">
                                <label htmlFor="salePrice">Selling Price </label>
                                <input
                                    className="input"
                                    type="number"
                                    id="salePrice"
                                    min="0"
                                    step="0.01" // Allows decimal values with up to two decimal places
                                    value={formData.salePrice}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <SearchSelect
                                label="Supplier"
                                placeholder="Buscar proveedor..."
                                onSelected={handleProviderSelect}
                                apiUrl={`${API}/api/v1/provider`}
                                optionsAttr="providers"
                                initialSelectedOption={relationalData.provider}
                                isRequired={true}
                            />

                            <SearchSelect
                                label="Category"
                                placeholder="Buscar categoría..."
                                onSelected={handleCategorySelect}
                                apiUrl={`${API}/api/v1/category`}
                                optionsAttr="categories"
                                initialSelectedOption={relationalData.category}
                                isRequired={true}
                            />
                        </div>

                        <div className="button-container">
                            <button className="btn" type="submit" disabled={submitDisabled}>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <Loading />
            )}

        </div>
    );
}

export default EditItem;
