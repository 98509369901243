import React, { useState, useEffect } from 'react';
import { API } from '../../env';
import Pagination from '../../components/pagination/Pagination';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Sales-report.css'; 

const SalesReport = () => {
  localStorage.setItem('selectedView', 'sales-report');
  
  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  const [formData, setFormData] = useState({ endDate: today, startDate: today});
  const [reportData, setReportData] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState({});
  const pageSize = 20; // Adjust the page size as needed
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

  useEffect(() => {
    fetchReportData(); // Fetch report data whenever the component mounts or formData changes
  }, [page, formData]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [id]: value }));
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= paginator.totalPages) {
      setPage(newPage);
    }
  };

  const fetchReportData = async () => {
    if (!formData.startDate || !formData.endDate) return;

    setSubmitDisabled(true);
    setLoading(true);

    const requestData = { ...formData };

    try {
      const url = new URL(`${API}/api/v1/reports/salesReport`);
      url.searchParams.append('page', page);
      url.searchParams.append('pageSize', pageSize);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        const result = await response.json();
        setPaginator(result);
        setReportData(result);
        toast.success("Report fetched successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
      } else {
        console.error("Error fetching report", response.statusText);
        toast.error(`Error fetching report: ${response.statusText}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000
        });
      }
    } catch (error) {
      console.error("The report could not be fetched", error);
      toast.error("The report could not be fetched", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      });
    } finally {
      setSubmitDisabled(false);
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchReportData();
  };

  return (
    <div className="reports-container">
      <h1>Sales Report</h1>
      <form onSubmit={handleSubmit}>
        <div className='formbox'>
          <div className="formitem">
            <label htmlFor="startDate">Start Date :</label>
            <input 
              type="date" 
              id="startDate" 
              value={formData.startDate} 
              onChange={handleChange} 
              required
            />
          </div>
          <div className="formitem">
            <label htmlFor="endDate">End Date :</label>
            <input 
              type="date" 
              id="endDate" 
              value={formData.endDate} 
              onChange={handleChange} 
              required 
            />
          </div>
          <div className="button-container">
            <button className="btn" type="submit" disabled={submitDisabled}>
              {loading ? 'Generating Report...' : 'Generate Report'}
            </button>
          </div>
        </div>
      </form>

      {reportData && (
        <div className="report-results">
          <h2 align="center" ><b>Sales Report from {new Date(formData.startDate).toLocaleDateString('en-GB', options)} to {new Date(formData.endDate).toLocaleDateString('en-GB', options)}</b></h2>
          <table className="styled-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Date</th>
                <th>Invoice Number</th>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {reportData.sales && reportData.sales.length > 0 ? (
                reportData.sales.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.saleId}</td>
                    <td>{new Date(entry.saleDate).toLocaleDateString('en-GB', options)}</td>
                    <td>{entry.billInvoiceNumber}</td>
                    <td>{entry.customer.name}</td>
                    <td>{entry.totalValue}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No results found</td>
                </tr>
              )}
              {page === paginator.totalPages && paginator.sales && paginator.sales.length > 0 && (
                <tr style={{ backgroundColor: 'lightblue', color: 'black' }}>
                  <td style={{ fontWeight: 'bold' }}>Total</td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: 'bold' }}>{reportData.saleCount}</td>
                  <td style={{ fontWeight: 'bold' }}>{reportData.totalSales}</td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination paginator={paginator} onChangePage={handlePageChange} />
        </div>
      )}
    </div>
  );
};

export default SalesReport;
