import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBasketShopping,faTrashCan } from '@fortawesome/free-solid-svg-icons';
import './purchases.css';
import '../../styles/addbox.css';
import SearchBox from '../../components/search-box/SearchBox';
import Pagination from '../../components/pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import userVerification from '../../utils/userVerification';
import { API } from '../../env';
import formatDate from '../../utils/formatDate';
import Loading from '../../components/loading/Loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Purchases = () => {
    localStorage.setItem('selectedView', 'purchases');
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    const pageSize = 20;

    const [isLoading, setIsLoading] = useState(true);

    const [paginator, setPaginator] = useState({});
    const [showConfirm, setShowConfirm] = useState(false);
    const [purchaseToDelete, setPurchaseToDelete] = useState(null);

    const navigate = useNavigate();
    toast.configure();
    useEffect(() => {
        // Permission validation
        if (!userVerification().isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }

        // Query paginated data
        const data = new FormData();
        if (query.length > 0) {
            data.append('searchCriteria', query);
        }
        data.append('page', page);
        data.append('pageSize', pageSize);

        const url = new URL(`${API}/api/v1/purchase`);
        url.search = new URLSearchParams(data).toString();
        (async () => {
              await fetch(url,{ 
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json' 
                }
            })
                .then(response => response.json())
                .then(data => {
                    setPaginator(data);
                    setIsLoading(false);
                })
                .catch(error => console.log(error))
        })();
    }, [navigate, query, page]);

    const handleSearch = (query) => {
        setQuery(query);
    }

    const handlePage = (page) => {
        setPage(page);
    }
    const handleDeleteClick = (purchaseId) => {
        setPurchaseToDelete(purchaseId);
        setShowConfirm(true);
    }

    const confirmDelete = async () => {
        if (!purchaseToDelete) return;

        // Optimistically remove purchase from state
        setPaginator(prevState => ({
            ...prevState,
            purchases: prevState.purchases.filter(purchase => purchase.purchaseId !== purchaseToDelete)
        }));

        try {
            const response = await fetch(`${API}/api/v1/purchase/${purchaseToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'),
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                toast.warn("Purchase Deleted successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false
                });
                setPage(1); // Optionally reset to first page
                setQuery(''); // Optionally reset the search query
            } else {
                console.error('Failed to delete the purchase');
                toast.error("Failed to delete the purchase", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false
                });
                // Re-add purchase to state if deletion failed
                setPaginator(prevState => ({
                    ...prevState,
                    purchases: [...prevState.purchases, { purchaseId: purchaseToDelete }]
                }));
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error("Error occurred while deleting the purchase", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: false
            });
            // Re-add purchase to state if an error occurred
            setPaginator(prevState => ({
                ...prevState,
                purchases: [...prevState.purchases, { purchaseId: purchaseToDelete }]
            }));
        } finally {
            setShowConfirm(false);
            setPurchaseToDelete(null);
        }
    };

    const cancelDelete = () => {
        setShowConfirm(false);
        setPurchaseToDelete(null);
    };

    const ConfirmationPopup = ({ show, onConfirm, onCancel }) => {
        if (!show) return null;

        return (
            <div className="confirmation-popup">
                <div className="confirmation-popup-content">
                    <p>Are you sure you want to delete?</p>
                    <button className="yes-btn" onClick={onConfirm}>Yes</button>
                    <button onClick={onCancel}>Cancel</button>
                </div>
            </div>
        );
    };

    return (
        <div className="purchases-container">


            <div className="text">Purchases <FontAwesomeIcon icon={faBasketShopping} className="details-icon" /> </div>

            <div className="options">
                <SearchBox onSearch={handleSearch} disabled={isLoading} />
                <Link to="/new-purchase" className="add-box">
                    <FontAwesomeIcon icon={faPlus} className="icon" />
                    <span className="text">New purchase</span>
                </Link>
            </div>

            {!isLoading ? (
                <div className="table-container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Total</th>
                                <th>Supplier</th>
                                <th>User</th>
                                <th>Details</th>
                                <th>Delete</th>

                            </tr>
                        </thead>
                        <tbody>
                            {paginator.purchases && paginator.purchases.length > 0 ? (
                                paginator.purchases.map(purchase => (
                                    <tr key={purchase.purchaseId}>
                                        <td>{purchase.purchaseId}</td>
                                        <td>{formatDate(purchase.createdAt)}</td>
                                        <td>{purchase.totalValue}</td>
                                        <td>{purchase.provider.name}</td>
                                        <td>{purchase.user.name}</td>
                                        <td>
                                            <Link to={`/detail-purchase/${purchase.purchaseId}`}>
                                                <FontAwesomeIcon icon={faBasketShopping} className="details-icon" />
                                            </Link>
                                        </td>
                                        <td>
                                            
                                            <FontAwesomeIcon 
                                                   icon={faTrashCan} 
                                                   className="trash-icon" 
                                                   onClick={() => handleDeleteClick(purchase.purchaseId)}
                                               />
                                           </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">No results found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <Pagination paginator={paginator} onChangePage={handlePage} />
                </div>
            ) : (
                <Loading />
            )}
                <ConfirmationPopup 
                show={showConfirm}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
            />
        </div>
    );
}

export default Purchases;
