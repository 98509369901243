import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faHouse, faTags, faBoxesStacked, faTruckFast, faBasketShopping, faFileLines,faUsers, faHandHoldingDollar, faUsersGear, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo.png';
import './sidebar.css'; 

const Sidebar = () => {
    const location = useLocation();


    const [user, setUser] = useState(null);

    // Minimize or show sidebar
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Selected view
    const [selectedView, setSelectedView] = useState(localStorage.getItem('selectedView') || 'home');
 
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')));
    }, []);

    useEffect(() => {
        setSelectedView(localStorage.getItem('selectedView'));
    }, [location]);

    return (
        <nav className={`sidebar-container ${isSidebarOpen ? 'close' : ''}`}>
            <header>
                <div className="image-text">
                    <span className="image">
                        <img src={logo} alt="" />
                    </span>
                    <div className="text logo-text">
                        <span className="name">Golden</span>
                        <span className="profession">Melano</span>
                    </div>
                </div>
                <FontAwesomeIcon
                    icon={faAngleRight}
                    className="toggle"
                    onClick={toggleSidebar}
                />
            </header>
            <div className="menu-bar">
                <div className="menu">
                    <ul className="menu-links">
                        <li className={`nav-link ${selectedView === "home" ? "selected" : ""}`}>
                            <Link to="/home">
                                <FontAwesomeIcon icon={faHouse} className="icon" />
                                <span className="text nav-text">Home</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "categories" ? "selected" : ""}`}>
                            <Link to="/categories">
                                <FontAwesomeIcon icon={faTags} className="icon" />
                                <span className="text nav-text">Category</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "items" ? "selected" : ""}`}>
                            <Link to="/items">
                                <FontAwesomeIcon icon={faBoxesStacked} className="icon" />
                                <span className="text nav-text">Items</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "providers" ? "selected" : ""}`}>
                            <Link to="/providers">
                                <FontAwesomeIcon icon={faTruckFast} className="icon" />
                                <span className="text nav-text">Suppliers</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "purchases" ? "selected" : ""}`}>
                            <Link to="/purchases">
                                <FontAwesomeIcon icon={faBasketShopping} className="icon" />
                                <span className="text nav-text">Purchases</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "customers" ? "selected" : ""}`}>
                            <Link to="/customers">
                                <FontAwesomeIcon icon={faUsers} className="icon" />
                                <span className="text nav-text">Customers</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "sales" ? "selected" : ""}`}>
                            <Link to="/sales">
                                <FontAwesomeIcon icon={faHandHoldingDollar} className="icon" />
                                <span className="text nav-text">Sales</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "payment" ? "selected" : ""}`}>
                            <Link to="/payment">
                                <FontAwesomeIcon icon={faDollarSign} className="icon" />
                                <span className="text nav-text">Payment</span>
                            </Link>
                        </li>  
                        <li className={`nav-link ${selectedView === "inventory-report" ? "selected" : ""}`}>
                            <Link to="/inventory-report">
                                <FontAwesomeIcon icon={faFileLines} className="icon" />
                                <span className="text nav-text">Inventory Report</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "ledger-report" ? "selected" : ""}`}>
                            <Link to="/ledger-report">
                                <FontAwesomeIcon icon={faFileLines} className="icon" />
                                <span className="text nav-text">Ledger Report</span>
                            </Link>
                        </li>   
                         <li className={`nav-link ${selectedView === "accounts-payable" ? "selected" : ""}`}>
                            <Link to="/accounts-Payable">
                                <FontAwesomeIcon icon={faFileLines} className="icon" />
                                <span className="text nav-text">Accounts Payable</span>
                            </Link>
                        </li>    <li className={`nav-link ${selectedView === "accounts-receivable" ? "selected" : ""}`}>
                            <Link to="/accounts-receivable">
                                <FontAwesomeIcon icon={faFileLines} className="icon" />
                                <span className="text nav-text">Accounts Receivable</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "sales-report" ? "selected" : ""}`}>
                            <Link to="/sales-report">
                                <FontAwesomeIcon icon={faFileLines} className="icon" />
                                <span className="text nav-text">Sales Report</span>
                            </Link>
                        </li>
                        <li className={`nav-link ${selectedView === "purchase-report" ? "selected" : ""}`}>
                            <Link to="/purchase-report">
                                <FontAwesomeIcon icon={faFileLines} className="icon" />
                                <span className="text nav-text">Purchase Report</span>
                                </Link>
                        </li>                         
                    </ul>
                </div>
                {/* Only if the user is admin */}
                { user && user.admin === true && (
                    <div className="bottom-content">
                        <ul>
                            <li className={`${selectedView === "users" ? "selected" : ""}`}>
                                <Link to="/users">
                                    <FontAwesomeIcon icon={faUsersGear} className="icon" />
                                    <span className="text nav-text">Users</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Sidebar;
