import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCartPlus,faDollarSign,faTrashCan } from '@fortawesome/free-solid-svg-icons';
import './payment.css';
import '../../styles/addbox.css';
import SearchBox from '../../components/search-box/SearchBox';
import Pagination from '../../components/pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import userVerification from '../../utils/userVerification';
import { API } from '../../env';
import formatDate from '../../utils/formatDate';
import Loading from '../../components/loading/Loading';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Payment = () => {
    localStorage.setItem('selectedView', 'payment');
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    const pageSize = 10;

    const [isLoading, setIsLoading] = useState(true);
    const [paginator, setPaginator] = useState({ payments: [] }); // Ensure payments is initialized as an array
    const [showConfirm, setShowConfirm] = useState(false);
    const [paymentToDelete, setPaymentToDelete] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Permission validation
        if (!userVerification().isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }

        // Construct URL with search params
        const url = new URL(`${API}/api/v1/payments`);
        const params = new URLSearchParams({
            page,
            pageSize,
            searchCriteria: query || ''
        });
        url.search = params.toString();

        (async () => {
            try {
                const response =   await fetch(url,{ 
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json' 
                }
            });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setPaginator(data);
            } catch (error) {
                console.error('Failed to fetch payments:', error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [navigate, query, page]);

    const handleSearch = (query) => {
        setQuery(query);
    }

    const handlePage = (page) => {
        setPage(page);
    }
     const handleDeleteClick = (paymentId) => {
        setPaymentToDelete(paymentId);
        setShowConfirm(true);
    }

    const confirmDelete = async () => {
        if (!paymentToDelete) return;
    
        setPaginator(prevState => ({
            ...prevState,
            payments: prevState.payments.filter(payment => payment.paymentId !== paymentToDelete)
        }));
    
        try {
            const response = await fetch(`${API}/api/v1/payments/${paymentToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'),
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.ok) {
                toast.warn("Payment Deleted successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false
                });
            } else {
                console.error('Failed to delete the payment');
                setPaginator(prevState => ({
                    ...prevState,
                    payments: [...prevState.payments, { paymentId: paymentToDelete }]
                }));
            }
        } catch (error) {
            console.error('Error:', error);
            setPaginator(prevState => ({
                ...prevState,
                payments: [...prevState.payments, { paymentId: paymentToDelete }]
            }));
        } finally {
            setShowConfirm(false);
            setPaymentToDelete(null);
        }
    };

    const cancelDelete = () => {
        setShowConfirm(false);
        setPaymentToDelete(null);
    };

    const ConfirmationPopup = ({ show, onConfirm, onCancel }) => {
        if (!show) return null;

        return (
            <div className="confirmation-popup">
                <div className="confirmation-popup-content">
                    <p>Are you sure you want to delete?</p>
                    <button className="yes-btn" onClick={onConfirm}>Yes</button>
                    <button className="no-btn" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        );
    };

    return (
        <div className="sales-container">
            <div className="text">Payment   <FontAwesomeIcon icon={faDollarSign} className="icon" /></div>
            <div className="options">
                <SearchBox onSearch={handleSearch} disabled={isLoading} />
                <Link to="/new-payment" className="add-box">
                    <FontAwesomeIcon icon={faPlus} className="icon" />
                    <span className="text">New Payment</span>
                </Link>
            </div>

            {!isLoading ? (
                <div className="table-container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Mode</th>
                                <th>Details</th>
                                <th>Amount</th>
                                <th>Delete</th>

                            </tr>
                        </thead>
                        <tbody>
                            {paginator.payments && paginator.payments.length > 0 ? (
                                paginator.payments.map(payment => (
                                    <tr key={payment.paymentId}>
                                        <td>{payment.paymentId}</td>
                                        <td>{formatDate(payment.paymentDate)}</td>
                                        <td>{payment.name}</td>
                                        <td>{payment.transactionType}</td>
                                        <td>{payment.paymentMethod}</td>
                                        <td>{payment.details}</td>
                                        <td>{payment.amount}</td>
                                        <td>  <FontAwesomeIcon 
                                                icon={faTrashCan} 
                                                className="trash-icon" 
                                                onClick={() => handleDeleteClick(payment.paymentId)}
                                            /></td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">No results found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <Pagination paginator={paginator} onChangePage={handlePage} />
                </div>
            ) : (
                <Loading />
            )}
              <ConfirmationPopup 
                show={showConfirm}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
            />
        </div>
    );
}

export default Payment;
