import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import userVerification from '../../../utils/userVerification';
import { API } from '../../../env';
import SearchSelect from '../../../components/search-select/SearchSelect'; // Assuming this component is used for dropdowns
import './new-payment.css'; // Create this CSS file for styling
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const NewPayment = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        paymentDate: new Date().toISOString().split('T')[0] ,
        clientType: '',
        customerId: '',
        supplierId: '',
        transactionType: '',
        details: '',
        paymentMethod: '',
        amount: '',
        userId:0
    });

    const [clients, setClients] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {
        const userVer = userVerification();
        if (!userVer.isAuthenticated) {
            localStorage.clear();
            navigate('/login');
            return;
        }
        setFormData({
            ...formData,
            userId: userVer.user.userId
        });

  
    }, [navigate]);

    const handleClientSelection = (customer) => {
        setFormData({
            ...formData,
            customerId: customer.type==='customer' ? customer.id :null,
            supplierId: customer.type==='supplier' ? customer.id :null,
            clientType: customer.type.toUpperCase()
        });
    }
    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value
        }); 
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSubmitDisabled(true);
        try {
            const response = await fetch(`${API}/api/v1/payments`, {
                method: 'POST',
                headers: {
                    'Authorization': localStorage.getItem('jwtToken'), 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                console.log('payment registered successfully');
                toast.success("Payment registered successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  });
                navigate('/payment');
            } else {
                console.error("Error creating payment");
            toast.error("Error creating payment", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
              })
            }
        } catch (error) {
            console.error("The payment could not be registered, verify the data");
            toast.error("The payment could not be registered, verify the data", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
              })
        }
        setSubmitDisabled(false);
    };

    return (
        <div className="new-payment-container">
            <div className="text">New Payment   <FontAwesomeIcon icon={faDollarSign} className="icon"  /></div>
            <div className="form-container">
                <form onSubmit={handleSubmit} autocomplete="off">
                    <div className="grid-form">
                        <div className="form-item">
                            <label htmlFor="paymentDate">Payment Date</label>
                            <input
                                type="date"
                                id="paymentDate"
                                value={formData.paymentDate}
                                onChange={handleChange}
                                required
                            />
                        </div>

                         <div className="form-item">
                            <label htmlFor="clientId">Client</label>
                            <SearchSelect
                                placeholder="Search for client..."
                                onSelected={handleClientSelection}
                                apiUrl={`${API}/api/v1/data/clients`}
                                optionsAttr="clients"
                                isRequired={true}
                            />                           
                        </div> 

                        <div className="form-item">
                            <label htmlFor="transactionType">Transaction Type</label>
                            <select
                                id="transactionType"
                                value={formData.transactionType}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select...</option>
                                <option value="DEBIT">Pay</option>
                                <option value="CREDIT">Receive</option>
                            </select>
                        </div>

                        <div className="form-item">
                            <label htmlFor="details">Details</label>
                            <input
                                type="text"
                                id="details"
                                value={formData.details}
                                onChange={handleChange}
                                
                            />
                        </div>

                        <div className="form-item">
                            <label htmlFor="paymentMethod">Payment Method</label>
                            <select
                                id="paymentMethod"
                                value={formData.paymentMethod}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select...</option>
                                <option value="CASH">Cash</option>
                                <option value="BANK">Bank</option>
                            </select>
                        </div>

                        <div className="form-item">
                            <label htmlFor="amount">Amount</label>
                            <input
                                type="number"
                                id="amount"
                                min="0"
                                step="0.05"
                                value={formData.amount}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="button-container">
                        <button className="btn" style={{ marginLeft: '37%' }}type="submit"  disabled={submitDisabled}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewPayment;
