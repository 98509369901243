import React, { useEffect, useState } from 'react';
import { API } from '../../env';
import SearchSelect from '../../components/search-select/SearchSelect';
import Pagination from '../../components/pagination/Pagination';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Ledger-report.css';

const LedgerReport = () => {
    localStorage.setItem('selectedView', 'ledger-report');
    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        clientId: '',
        clientType: ''
    });
    const [clients, setClients] = useState([]);
    const [reportData, setReportData] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [page, setPage] = useState(1);
    const [paginator, setPaginator] = useState({ transactions: [], page: 1, totalPages: 1, totalEntries: 0 });
    const [shouldFetchReport, setShouldFetchReport] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = 20;

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await fetch(`${API}/api/v1/data/clients`);
                const data = await response.json();
                setClients(data.clients);
            } catch (error) {
                console.error("Error fetching clients", error);
                toast.error("Error fetching clients", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            }
        };

        fetchClients();
    }, []);

    useEffect(() => {
        if (!shouldFetchReport) return;

        const fetchReportData = async () => {
            if (!formData.startDate || !formData.endDate || !formData.clientId) return;

            setIsLoading(true);
            setSubmitDisabled(true);
            try {
                const reportFetchDTO = {
                    startDate: formData.startDate,
                    endDate: formData.endDate,
                    clientId: formData.clientId,
                    clientType: formData.clientType.toUpperCase()
                };

                const url = new URL(`${API}/api/v1/reports/ledgerReport`);
                const params = new URLSearchParams({
                    page,
                    pageSize,
                    ...reportFetchDTO
                });
                url.search = params.toString();

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': localStorage.getItem('jwtToken'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(reportFetchDTO)
                });

                if (response.ok) {
                    const data = await response.json();
                    setReportData(data);
                    setPaginator({
                        transactions: data.transactions,
                        page: data.page,
                        totalPages: data.totalPages,
                        totalEntries: data.totalEntries
                    });
                    toast.success("Report fetched successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                } else {
                    console.error("Error fetching report");
                    toast.error("Error fetching report", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000
                    });
                }
            } catch (error) {
                console.error("The report could not be fetched", error);
                toast.error("The report could not be fetched", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            } finally {
                setIsLoading(false);
                setSubmitDisabled(false);
                setShouldFetchReport(false);
            }
        };

        fetchReportData();
    }, [shouldFetchReport, page, formData]);

    const handleClientSelection = (client) => {
        setFormData({
            ...formData,
            clientId: client.id,
            clientType: client.type
        });
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setPage(1); // Reset page to 1 when submitting new search
        setShouldFetchReport(true); // Set flag to fetch report
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= paginator.totalPages) {
            setPage(newPage);
            setShouldFetchReport(true); // Trigger report fetch when page changes
        }
    };

    return (
        <div className="reports-container">
            <h1>Client Ledger Report</h1>
            <form onSubmit={handleSubmit}>
                <div className='formbox'>
                    <div className="formitem">
                        <label htmlFor="startDate">Start Date</label>
                        <input
                            type="date"
                            id="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                           
                        />
                    </div>

                    <div className="formitem">
                        <label htmlFor="endDate">End Date</label>
                        <input
                            type="date"
                            id="endDate"
                            value={formData.endDate}
                            onChange={handleChange}
                         
                        />
                    </div>

                    <div className="formitem spcl ">
                        <label htmlFor="clientId">Client</label>
                        <SearchSelect
                            placeholder="Search for client..."
                            onSelected={handleClientSelection}
                            apiUrl={`${API}/api/v1/data/clients`}
                            optionsAttr="clients"
                            isRequired={true}
                        />
                    </div>
                    <div className="button-container">
                        <button className="btn buttons spcl" type="submit" disabled={submitDisabled}>
                            Generate Report
                        </button>
                    </div>
                </div>
            </form>

            {isLoading ? (
                <div>Loading...</div> // Replace with a proper loading component if available
            ) : (
                reportData && (
                    <div className="report-results">
          <h2 className='report-head'>Report for {reportData.clientName} </h2>
          <h2 align="center"><b>Report from {formData.startDate} to {formData.endDate}</b></h2>
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Details</th>
                                    <th>Credit</th>
                                    <th>Debit</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginator.transactions.length > 0 ? (
                                    paginator.transactions.map((entry, index) => (
                                        <tr key={index}>
                                            <td>{entry.date}</td>
                                            <td>{entry.details}</td>
                                            <td>{entry.transactionType === "CREDIT" ? entry.amount : ""}</td>
                                            <td>{entry.transactionType === "DEBIT" ? entry.amount : ""}</td>
                                            <td>{entry.balance}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No results found</td>
                                    </tr>
                                )}
                                {page === paginator.totalPages && paginator.transactions.length > 0 && (
                                    <tr style={{ backgroundColor: 'lightblue', color: 'black' }}>
                                        <td style={{ fontWeight: 'bold' }}>Total</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ fontWeight: 'bold' }}>{reportData.balance}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <Pagination paginator={paginator} onChangePage={handlePageChange} />
                    </div>
                )
            )}
        </div>
    );
};

export default LedgerReport;